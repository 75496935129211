

































































import { Vue, Component, Watch } from "vue-property-decorator";
import Rview from "@/components/routerView/index.vue";
import { OtherModule } from "@/store/other";
import { UserModule } from "@/store/user";
import Bread from "@/components/bread/index.vue";
import { api_user } from "@/api";
@Component({
  components: {
    Rview,
    Bread,
  },
})
export default class extends Vue {
  showInfo = false;

  get is_mobile() {
    return OtherModule.is_mobile;
  }

  get user_info() {
    return UserModule.info;
  }

  async unregister(){
		await this.$confirm('정말 회원탈퇴를 진행하시겠습니까? 회원을 탈퇴하시는 경우, 모든 데이터가 삭제됩니다.', '알림', {
			confirmButtonText: '확인',
			cancelButtonText: '취소',
		})
		await api_user.unregister()
		this.logout('탈퇴 완료되었습니다.', 'unregister')
	}

  async logout(str = "로그아웃 되었습니다.", type:string) {
    if(type !== 'unregister'){
      await this.$confirm('정말 로그아웃 하시겠습니까?', '알림', {
        confirmButtonText: '확인',
        cancelButtonText: '취소',
      })
    }
    UserModule.logout();
    this.$message.success(str);
    this.$router.push("/");
  }

  @Watch("$route", { immediate: true })
  watch_route(res: any) {
    this.showInfo = res.params.id ? true : false;
  }
}
